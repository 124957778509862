import Vue from 'vue'
import VueRouter from 'vue-router'

import Main from "@/components/Main";
import Login from "@/components/Login";
import NotFoundComponent from "@/components/NotFoundComponent";

import Guides from "@/components/Guides";
import Settings from "@/components/Settings";

import Feedback from "@/pages/feedback/Feedback";
import Address from "@/pages/address/Address";
import Candidates from "@/pages/candidate/Candidates";
import Need from "@/pages/need/Need";

import ProjectsSet from "@/pages/settings/ProjectsSet";
import ChannelsSet from "@/pages/settings/ChannelsSet";
import ResultsSet from "@/pages/settings/ResultsSet";
import LeadtypeSet from "@/pages/settings/LeadtypeSet";
import VacancylistSet from "@/pages/settings/VacancylistSet";
import UsersSet from "@/pages/settings/UsersSet";
import BackupSet from "@/pages/settings/BackupSet";
import ErrorlogSet from "@/pages/settings/ErrorlogSet";
import CronmanSet from "@/pages/settings/CronmanSet";
import AboutSet from "@/pages/settings/AboutSet";
import ServerSet from "@/pages/settings/ServerSet";
import SmtpSet from "@/pages/settings/SmtpSet";
import IntegrationSet from "@/pages/settings/IntegrationSet";
import HRIntegrationSet from "@/pages/settings/HRIntegrationSet";
import SmstemplatesSet from "@/pages/settings/SmstemplatesSet";
import TranslateSet from "@/pages/settings/TranslateSet";
import UserlogsSet from "@/pages/settings/UserlogsSet";
import StatisticSet from "@/pages/settings/StatisticSet";
import EmailtemplatesSet from "@/pages/settings/EmailtemplatesSet";
import Operator from "@/pages/operator/Operator";
import FeedbackSteps from "../pages/feedback/FeedbackSteps";
import MeetControl from "../pages/mcontrol/MeetControl";
import JobControl from "../pages/jobcontrol/JobControl";
import Meets from "@/pages/meets/Meets";
import Tasks from "../pages/task/Tasks";
import Marketing from "../pages/marketing/Marketing";
import MarketingTarif from "../pages/marketing/MarketingTarif";
import MarketingZarplata from "../pages/marketing/MarketingZarplata";
import MarketingEmpty from "@/pages/marketing/MarketingEmpty.vue";
//import Reports from "@/pages/reports/reports";

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'main',
		component: Main,
	},
	{
		path: '/login',
		name: 'login',
		component: Login,
	},
	{
		path: '/operator',
		name: 'operator',
		component: Operator
	},
	{
		path: '/steps',
		name: 'steps',
		component: FeedbackSteps,
	},
	{
		path: '/mcontrol',
		name: 'mcontrol',
		component: MeetControl,
	},
	{
		path: '/jcontrol',
		name: 'jcontrol',
		component: JobControl,
	},
	{
		path: "/meets",
		name: "meets",
		component: Meets
	},
	{
		path: '/pages',
		name: 'pages',
		component: Guides,
		redirect: '/pages/feedback',
		children: [
			{
				name: "feedback",
				path: "feedback",
				component: Feedback
			},
			{
				name: "task",
				path: "task",
				component: Tasks
			},
			{
				name: "address",
				path: "address",
				component: Address
			},
			{
				name: "candidates",
				path: "candidates",
				component: Candidates
			},
			{
				name: "need",
				path: "need",
				component: Need
			},
			{
				name: "meets2",
				path: "meets",
				component: Meets
			}
		],
	},
	{
		path: '/marketing',
		name: 'marketing',
		component: Guides,
		redirect: '/marketing/campaign',
		children: [
			{
				name: "campaign",
				path: "campaign",
				component: Marketing
			},
			{
				name: "empty",
				path: "empty",
				component: MarketingEmpty
			},
			{
				name: "tarif",
				path: "tarif",
				component: MarketingTarif
			},
			{
				name: "zarplata",
				path: "zarplata",
				component: MarketingZarplata
			}
		],
	},
	/*{
		path: '/reports',
		name: 'reports',
		component: Reports,
		redirect: '/reports/:id',
		children: [
			{
				name: "feedback",
				path: "feedback",
				component: Feedback
			},
		],
	},*/
	{
		path: '/settings',
		name: 'settings',
		component: Settings,
		redirect: '/settings/projects',
		children: [
			{
				name: "projects",
				path: "projects",
				component: ProjectsSet
			},
			{
				name: "channels",
				path: "channels",
				component: ChannelsSet
			},
			{
				name: "results",
				path: "results",
				component: ResultsSet
			},
			{
				name: "leadtype",
				path: "leadtype",
				component: LeadtypeSet
			},
			{
				name: "vacancylist",
				path: "vacancylist",
				component: VacancylistSet
			},
			{
				name: "users",
				path: "users",
				component: UsersSet
			},
			{
				name: "backup",
				path: "backup",
				component: BackupSet
			},
			{
				name: "userlogs",
				path: "userlogs",
				component: UserlogsSet
			},
			{
				name: "errors",
				path: "errors",
				component: ErrorlogSet
			},
			{
				name: "cronman",
				path: "cronman",
				component: CronmanSet
			},
			{
				name: "about",
				path: "about",
				component: AboutSet
			},
			{
				name: "server",
				path: "server",
				component: ServerSet
			},
			{
				name: "smtp",
				path: "smtp",
				component: SmtpSet
			},
			{
				name: "integration",
				path: "integration",
				component: IntegrationSet
			},
			{
				name: "hrintegration",
				path: "hrintegration",
				component: HRIntegrationSet
			},
			{
				name: "smstemplates",
				path: "smstemplates",
				component: SmstemplatesSet
			},
			{
				name: "translate",
				path: "translate",
				component: TranslateSet
			},
			{
				name: "statistic",
				path: "statistic",
				component: StatisticSet
			},
			{
				name: "template",
				path: "template",
				component: EmailtemplatesSet
			},
		],
	},
	{ path: '*', component: NotFoundComponent }
]

const router = new VueRouter({
	linkActiveClass: 'ytab',
	linkExactActiveClass: 'current',
	//mode: 'history',
	base: process.env.NODE_ENV === 'production' ? '/app/' : '/',
	routes
})

export default router
