<template>

	<div>

		<div id="filter" class="w2002 top--filters">

			<div class="page--title disable--select">
				{{ language.MeetControl }}
			</div>

		</div>

		<div class="page--body">

			<div class="page--left">

				<div v-if="loading" class="loading"></div>

				<h2 class="uppercase hidden">{{language.MeetControl}}</h2>

				<div v-if="inwork === 0" class="wp100 mx-auto mt10 pt10">

					<div>Отклик пока не выбран</div>

				</div>
				<div v-else>

					<MeetControlForm :id="inwork" :schedule="scheduleid" :phone="phone" :isfirst="isfirst" :lastcomment="lastcomment" @update="worked" @close="cancelWork"></MeetControlForm>

				</div>

				<div class="space-100"></div>

			</div>
			<div class="page--right">

				<div v-if="loading" class="loading"></div>

				<div v-if="inwork === 0" class="feedback-roof text-center">

					<div class="mt20 pt40 fs-40">
						<i class="icon-calendar-1 green fs-40"></i>
					</div>
					<div class="mt20 fs-14">
						<p>В этом разделе происходит обработка встреч кандидатов с HR</p>
						<p class="fs-14">Ваша задача - зафиксировать результат этой <b class="green">встречи</b></p>
					</div>
					<div class="mt20 fs-20 flh-10 Bold">
						Вы готовы обработать встречу?
					</div>

					<!--
					<div class="space-50"></div>

					<hr>

					<div class="space-20"></div>

					<div class="Bold fs-12 green">Очередь встреч</div>

					<div class="space-20"></div>

					<div class="meets wp60 mx-auto">

						<div v-for="item in mlist" :key="item.id" class="infodiv dotted">

							<div class="Bold fs-12">{{item.soiskatel.title}}</div>
							<div class="fs-10">{{item.soiskatel.phone}}</div>
							<div class="fs-10">{{item.soiskatel.phone}}</div>

						</div>

					</div>
					-->


					<div class="space-30"></div>

					<div class="mt20">
						<a href="javascript:void(0)" @click="setInwork" class="button greenbtn custom fs-14 Bold">Взять в работу</a>
					</div>

				</div>
				<div v-else>

					<FeedbackCard :id="feedbackcard" :nobutton="true" :editabble="false" :mcontrol="true"></FeedbackCard>

				</div>

			</div>

			<div class="mt20 p0 bottom-block" data-id="address">

				<A href="javascript:void(0)" @click="stepReport" title="Отчет" class="ml10 blue">
					<i class="icon-download-1"></i>
				</A>

			</div>

		</div>

		<Dialog ref="dialog" :show="showdialog" :width="dialogwidth" :loaded="dialogloaded" @close="closeDialog">
			<template v-slot:dlgbody>
				<StepReport @close="closeDialog" @loaded="dialogLoaded"/>
			</template>
		</Dialog>

	</div>

</template>

<script>
import axios from "axios"

import FeedbackCard from "@/pages/feedback/FeedbackCard"
import MeetControlForm from "@/pages/mcontrol/MeetControlForm"
import StepReport from "@/pages/mcontrol/StepReport"
import Dialog from "@/components/modal/Dialog.vue";

export default {
	name: "MeetControl",
	components: {
		Dialog,
		FeedbackCard,
		MeetControlForm,
		StepReport
	},
	data() {
		return {
			loading: false,
			dialog: {},
			inwork: 0,
			scheduleid: 0,
			feedbackcard: 0,
			lastcomment: '',
			phone: '',
			isfirst: true,
			mcontrol: false,
			mlist: [],
			showdialog: false,
			dialogloaded: false,
			dialogwidth: 800,
			apiBaseURL: this.$store.getters.apiBaseURL,
			language: this.$store.state.language,
			UserID: this.$store.state.user.UserID
		}
	},

	created() {

		//console.log('created', this.$route.params)

		if(this.$route.params.scheduleid !== undefined) {

			this.scheduleid = this.$route.params.scheduleid
			this.getMeetInfo(this.scheduleid)

		}

		this.pageTitle = this.language.MeetControl

		//this.getList()

	},

	watch: {

		// при изменениях маршрута запрашиваем данные снова
		routes: 'manualWork'

	},

	methods: {

		setInwork(){

			let param = {};

			param['action'] = 'inwork'
			param['scheduleid'] = this.scheduleid

			this.loading = true

			axios.post(this.apiBaseURL + '/api/mcontrol/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			}).then(
				(data) => {

					//console.log(data.data)

					if(data.data.result.result === 'ok' && data.data.result.fid > 0){

						this.inwork = data.data.result.id
						this.scheduleid = data.data.result.scheduleid
						this.feedbackcard = data.data.result.fid
						this.phone = data.data.result.phone
						this.lastcomment = data.data.result.lastcomment
						this.isfirst = data.data.result.mtask > 0

					}
					else if(data.data.result.result === 'ok' && data.data.result.fid == null){

						this.$toast.warn({
							title: 'Внимание',
							message: "Очередь пуста. Обрабатывать нечего",
							position: "bottom center",
							timeOut: 3000,
							progressBar: true
						})

					}
					// если отклик уже кем-то взят в работу, то обновляем список
					else if(data.data.result.result === 'buzy'){

						// this.getLists()

						let vm = this

						// берем отклик в работу
						setTimeout(function () {
							vm.setInwork()
						}, 300)

					}

					this.loading = false

				},
			)

		},

		// для взятия в работу вручную (из раздела Встречи)
		manualWork(){

			//console.log('manual', this.$route.params)

			if(this.$route.params.scheduleid !== undefined) {

				this.getMeetInfo(this.$route.params.scheduleid)

			}

		},

		getMeetInfo(payload){

			let param = {};

			param['action'] = 'info'
			param['scheduleid'] = payload

			this.loading = true

			axios.post(this.apiBaseURL + '/api/mcontrol/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			}).then(
				(data) => {

					console.log(data.data.result)

					this.inwork = data.data.result.id
					this.scheduleid = payload
					this.feedbackcard = data.data.result.fid

					this.loading = false

				},
			)

		},

		worked(payload){

			//console.log(payload)

			// если создан новый отклик, то переходим в окно оператора
			if(payload['newfeedback'] > 0){

				this.$router.push("/operator")

			}
			// или предлагаем взять в работу новую встречу
			else{

				this.inwork = 0
				this.feedbackcard = 0
				this.mcontrol = false
				this.scheduleid = 0

			}

		},

		cancelWork(){

			this.inwork = 0
			this.feedbackcard = 0
			this.mcontrol = false
			this.scheduleid = 0

		},

		getList() {

			let param = {};

			this.loading = true

			param['limit'] = 5
			param['action'] = "listOfMeets"

			axios.post(this.apiBaseURL + '/api/mcontrol/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			}).then(
				(data) => {

					this.mlist = data.data.result.list

					console.log(this.mlist)

					this.loading = false
				},
			).catch(error => {

				this.loading = false

				this.$toast.error({
					title: 'Error',
					message: error.response.status + ": " + error.response.statusText,
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				})

			})

		},

		dialogLoaded() {
			this.dialogloaded = true
		},
		closeDialog() {
			this.showdialog = false
			this.dialogwidth = 800
			this.exportData = false
		},

		stepReport() {
			this.showdialog = true
			this.dialogwidth = 600
		},

	},

	metaInfo: {
		// if no subcomponents specify a metaInfo.title, this title will be used
		title: 'Meet Control',
		// all titles will be injected into this template
		titleTemplate: '%s. Hunter'
	}
}
</script>

<style scoped>
.ui-card.full .crd--body {
	height: calc(100vh - 90px);
}
.page--title{
	left: 45px;
	display: block;
	width: 200px !important;
	text-align: left;
}
.page--body{
	display               : grid;
	grid-template-columns : 1fr 1fr;
	grid-gap              : 10px 10px;
}
.mcontrol {
	display               : grid;
	grid-template-columns : 100px auto 40px;
	grid-gap              : 20px 20px;
}
.meets {
	display               : grid;
	grid-template-columns : 1fr 1fr 1fr;
	grid-gap              : 10px 10px;
}
.button.custom{
	padding: 20px 30px !important;
}
.feedback-roof{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--gray-lite);
	opacity: 0.9;
	z-index : 1;
}
</style>