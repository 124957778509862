<template>

	<div>

		<DIV class="zagolovok hidden">{{ language.JobControl }}</DIV>

		<div v-if="loading" class="content-loading"></div>

		<form v-if="!loading" v-cloak>

			<div id="formtabs" class="pr20 pl20">

				<div class="space-10"></div>

				<div class="divider mt10 mb20">
					<div class="red">{{ language.WorkResult }}</div>
				</div>

				<div class="space-10"></div>

				<div class="flex-container mt5 box--child">

					<div class="flex-string wp100 relative material">

						<textarea
							class="wp100 h200"
							id="comment"
							placeholder=" "
							ref="comment"
							v-model="form.comment"
							v-html="form.comment"
							spellcheck="false"
						/>
						<label for="comment">{{ language.Comment }}</label>

					</div>

					<div class="tagbox">
						<div v-for="tag in tags" :key="tag" class="inline disable--select">
							<div @click="textAtCursor(tag + ' ')" class="tag">{{ tag }}</div>
						</div>
					</div>

				</div>

				<div v-if="!form.newfeedback" class="flex-container mt20 box--child">

					<div class="flex-string wp100 mt20 relative material">

						<date-picker
							lang="ru"
							type="datetime"
							:show-second="false"
							format="DD.MM.YYYY H:mm"
							:default-value="form.totime"
							:minute-step="5"
							v-model="form.totime"
							valueType="format"
							@input="changeDate"
							:show-time-panel="showTimePanel"
							:open.sync="openPanel"
							@change="handleChange"
							@close="handleOpenChange"
							:disabled-date="notBeforeToday"
							:shortcuts="shortcuts"
						>

							<template v-slot:input>
								<div class="wp100 relative material">
									<input type="text" id="totime" class="wp100" placeholder=" " autocomplete="off" v-model="form.totime">
									<label for="totime">Перезвонить в</label>
								</div>
							</template>
							<template v-slot:icon-clear></template>
							<template v-slot:icon-calendar></template>
							<template v-slot:footer>
								<div class="blue p5 hand inline pull-left" @click="toggleTimePanel" :title="showTimePanel ? 'дата' : 'время'">
									<i :class="showTimePanel ? 'icon-calendar-inv' : 'icon-clock'"></i><span class="">{{ showTimePanel ? 'дата' : 'время' }}</span>
								</div>
								<div class="gray p5 hand inline pull-right" @click="openPanel = false" title="Завершить">
									<i class="icon-cancel-circled"></i>
								</div>
							</template>

						</date-picker>

					</div>

				</div>

				<div v-if="phone.length > 10" class="flex-container mt5 mb20 box&#45;&#45;child">

					<div class="flex-string wp100 relative text-center">
						<a v-bind:href="'tel:'+newphone" :title="language.Call" class="button greenbtn"><i class="icon-phone"></i> Начать звонок</a>
					</div>

				</div>

				<div class="flex-container mt20 box--child">

					<div class="flex-string wp100 relative material">

						<div class="checkbox mb20 pl20">
							<label class="middle">
								<input class="taskss" v-model="form.newfeedback" type="checkbox" :value="true" @click="changeNewFeedback">
								<span class="custom-checkbox"><i class="icon-ok"></i></span>
								<span class="mt5">Создать новый отклик</span>
							</label>
						</div>

					</div>

				</div>

			</div>

			<div class="button--pane text-right fs-12 Bold pr20 pl20">

				<button @click="formSubmit" class="button" :class="{'disabled':loading}"><i class="icon-ok"></i>{{ language.Processing }}</button>
				<button @click="formCancel" class="button graybtn"><i class="icon-cancel"></i>{{ language.Cancel }}</button>

			</div>

		</form>

	</div>

</template>

<script>
import axios from "axios";

export default {
	name: "JobControlForm",
	components: {},
	props: {
		id: Number,
		meetcontrolid: Number,
		phone: String,
		isfirst: Boolean
	},
	data() {
		return {
			//loading: false,
			form: {
				card: this.id,
				action: "workit",
				meetcontrolid: this.meetcontrolid,
				comment: "",
				totime: "", //this.$moment().add(30, 'm').format('DD.MM.YYYY HH:mm'),
				newfeedback: false,
			},
			telprefix: "34",
			tags: [
				'#ПерезвонЧерез2Недели',
				'#ПерезвонЧерезЧас',
				'#Оформлен',
				'#Дубль',
				'#НеБудуРаботать',
				/*'#УстроенЗдесь',
				'#УстроенДругоеМесто',
				'#НеПрошел',
				'#НеУстроило',
				'#График',
				'#Зарплата',
				'#Место',
				'#НеЗнаетЯзык',
				'#ПлохоЗнаетЯзык',
				'#Возраст',
				'#Оборудование',
				'#ПлохоГоворит',
				'#Странный',*/
			],
			openPanel: false,
			showTimePanel: false,
			showTimeRangePanel: false,
			guides: this.$store.state.guides,
			apiBaseURL: this.$store.getters.apiBaseURL,
			language: this.$store.state.language,
			loading: false,
			message: "",
			isAdmin: this.$store.state.user.isAdmin,
			shortcuts: this.$root.$data.shortcuts
		}
	},
	computed: {
		card() {
			return this.id > 0 ? this.id : 0
		},
		newphone() {
			return this.telprefix + this.phone.slice(1)
		}
	},
	created() {

		this.telprefix = "34"
		if(!this.isfirst){
			this.telprefix = "35"
		}

	},
	mounted() {

	},
	watch: {},
	methods: {
		formSubmit() {


			// отправка формы здесь
			let param = {};

			param['action'] = 'workit'
			param.id = this.card

			for (let key in this.form) {
				if (this.form[key]) {
					param[key] = this.form[key]
				}
			}

			if(this.form.totime === '' && this.form.comment === '' && this.form.newfeedback === false){

				this.$toast.error({
					title: 'Error',
					message: "Не заполнено ни одно поле",
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				})

				return

			}

			/*if(this.form.totime !== '') {
				param.totime = this.$moment(this.form.totime).format('YYYY-MM-DD HH:mm:00')
			}*/

			this.loading = true

			//console.log(param)

			axios.post(this.apiBaseURL + '/api/jobcontrol/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
					(data) => {

						console.log('form', data.data.result)

						this.message = data.data.result.result
						this.$emit("update", data.data.result)

						this.$toast.success({
							title: 'Success',
							message: data.data.result.result,
							position: "bottom center",
							timeOut: 3000,
							progressBar: true
						})

					},
				)
				.catch(error => {
					//console.log(error)

					this.loading = false

					this.$toast.error({
						title: 'Error',
						message: error.text,
						position: "bottom center",
						timeOut: 3000,
						progressBar: true
					})
				})

		},
		formCancel() {

			let param = {};

			param['action'] = 'outwork'
			param.id = this.card

			this.loading = true

			axios.post(this.apiBaseURL + '/api/jobcontrol/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 6000,
			}).then(
				(data) => {

					//console.log(data.data)

					if (data.data.result.result === 'ok') {

						this.$emit("close")

					}

					if (this.form.newfeedback) {

						this.$router.push("/operator")

					}

					this.loading = false

				},
			)

		},
		clearInput(element) {
			this.form[element] = ''
		},
		changeNewFeedback() {

			this.totime = this.form.newfeedback ? "" : this.$moment().add(30, 'm').format('DD.MM.YYYY HH:mm')

		},
		changeDate(payload) {
			this.form.totime = payload
		},
		toggleTimePanel() {
			this.showTimePanel = !this.showTimePanel;
		},
		handleOpenChange() {
			this.showTimePanel = false;
		},
		handleChange(value, type) {
			if (type === 'date') {
				this.toggleTimePanel()
			}
			if (type === 'minute') {
				this.openPanel = false
			}
		},
		notBeforeToday(date) {
			return date < new Date(new Date().setHours(0, 0, 0, 0));
		},

		textAtCursor(_text) {

			//console.log(_text)
			//console.log( this.$refs)

			let area = this.$refs.comment
			let self = this

			if ((area.selectionStart) || (area.selectionStart === 0)) {

				let p_start = area.selectionStart;
				let p_end = area.selectionEnd;

				area.value = area.value.substring(0, p_start) + _text + area.value.substring(p_end, area.value.length);

			}

			if (area.selection) {

				area.focus();
				let sel = area.selection.createRange();
				sel.text = _text;

			}

			self.form.comment = area.value

		}
	}
}
</script>

<style>


</style>