<template>

	<div>

		<DIV class="zagolovok">Экспорт адресов</DIV>

		<div v-if="loading" class="content-loading"></div>

		<form v-if="!loading" v-cloak>

			<div id="formtabs" class="pr20 pl20">

				<div class="flex-container mb10 mt20 box--child">

					<div class="flex-string wp100 relative material like-input pt20">

						<div class="flex-container">

							<div v-for="item in guides.projects" class="flex-string wp50 border-box" :key="item.id">
								<div class="checkbox mb10 pl20 fs-09 ">
									<label class="middle">
										<input type="checkbox" class="taskss" v-model="form.project" :value="item.id">
										<span class="custom-checkbox mt5" :class="{'secondary':!item.isactive}"><i class="icon-ok"></i></span>
										<span class="" :class="{'gray':!item.isactive}">{{ item.name }}</span>
										<span v-if="!item.isactive" class="pl10"><i class="icon-eye-off gray" title="Не активен"></i></span>
									</label>
								</div>
							</div>

						</div>

						<hr>

						<div class="flex-container">

							<div class="flex-string wp50 border-box">
								<div class="checkbox mb10 pl20 fs-09 ">
									<label class="middle">
										<input class="taskss" v-model="checkall" type="checkbox" id="checkall" :value="true" @click="checkAll">
										<span class="custom-checkbox alert mt5"><i class="icon-ok"></i></span>
										<span class="red Bold">Все проекты</span>
									</label>
								</div>
							</div>

							<div class="flex-string wp50 border-box">
								<div class="checkbox mb10 pl20 fs-09 ">
									<label class="middle">
										<input class="taskss" v-model="checkactive" type="checkbox" id="checkactive" :value="true" @click="checkActive">
										<span class="custom-checkbox success mt5"><i class="icon-ok"></i></span>
										<span class="green Bold">Активные проекты</span>
									</label>
								</div>
							</div>

						</div>

						<div class="label">{{language.Project}}</div>

					</div>

				</div>

				<div class="flex-container mb10 mt20 box--child">

					<div class="flex-string wp100 relative material like-input pt20">

						<div class="flex-container">

							<div class="checkbox mt10 mb10 pl20 fs-09">
								<label class="middle">
									<input id="isactive" type="checkbox" v-model="form.isactive">
									<span class="custom-checkbox"><i class="icon-ok"></i></span>
									<span class="Bold ml5"><b class="">Только активные</b></span>
								</label>
							</div>

						</div>
						<div class="label">Дополнительно</div>

					</div>

				</div>

				<div class="mb10 mt20 box--child"></div>

			</div>

			<div class="flex-container box--child button--pane fs-12 Bold">

				<div class="flex-string wp50 text-right">

					<button @click="formSubmit" class="button"><i class="icon-ok"></i>{{language.Export}}</button>
					<button @click="closeForm" class="button graybtn"><i class="icon-cancel"></i>{{language.Cancel}}</button>

				</div>

			</div>

		</form>

	</div>

</template>

<script>
import axios from "axios";

export default {
	name: "AddressExport",
	data() {
		return {
			form: {
				action: "export",
				project: [],
				isactive: true
			},
			guides: this.$store.state.guides,
			apiBaseURL: this.$store.getters.apiBaseURL,
			loading: false,
			message: "",
			showit: true,
			popmenu: false,
			checkall: false,
			checkactive: false,
			language: this.$store.state.language
		}
	},
	mounted() {
		//console.log(this.form)
		this.loading = false
		this.$emit("loaded")
	},
	methods: {
		formSubmit() {

			// отправка формы здесь
			let param = {};

			for (let key in this.form) {
				if (this.form[key]) {
					param[key] = this.form[key]
				}
			}

			this.loading = true

			//console.log(param)

			axios.post(this.apiBaseURL + '/api/points/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
					(data) => {

						window.open(this.apiBaseURL + '/' + data.data.result.url)

						this.loading = false

						this.closeForm()
						this.$emit("update")

						this.$toast.success({
							title: 'Success',
							message: data.data.result.message,
							position: "bottom center",
							timeOut: 3000,
							progressBar: true
						})

					},
				)
				.catch(error => {
					console.log(error)

					this.loading = false

					this.$toast.error({
						title: 'Error',
						message: error.text,
						position: "bottom center",
						timeOut: 3000,
						progressBar: true
					})
				})

			//console.log(this.submitStatus)

		},
		closeForm() {
			this.$emit("close")
		},
		checkAll(){

			this.checkall = !this.checkall

			switch (this.checkall){
				case true:

					this.form.project = this.guides.projects.map(function (e) {
						return e.id;
					})

					break
				case false:

					this.form.project = []

					break
			}

		},
		checkActive(){

			this.checkactive = !this.checkactive

			switch (this.checkactive){
				case true:

					this.form.project = this.guides.projects.map(function (e) {
						if(e.active) {
							return e.id;
						}
					})

					break
				case false:

					this.form.project = []

					break
			}

		},
	}
}
</script>

<style scoped>
.mx-datepicker{
	width: initial !important;
}

@media (min-width : 1100px) {

	.periodblock{
		font-size: 0.90em !important;
	}

}
</style>